import React, { useEffect, useState } from 'react';

//Mui Components
import {
  Alert,
  Box,
  Card,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  Typography,
} from '@mui/material';

// Assets

//Mui icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//utils
import { capitalizeFirstLetter } from '../../../utils';

export default function PortalBankAccounts(props) {
  const [sortedAccounts, setSortedAccounts] = useState([]);
  const dayFormatter = (number) => {
    if (number > 0) {
      let formattedNumber =
        parseInt(number) % 10 == 1 && parseInt(number) != 11
          ? number + 'st'
          : parseInt(number) % 10 == 2 && parseInt(number) != 12
          ? number + 'nd'
          : parseInt(number) % 10 == 3 && parseInt(number) != 13
          ? number + 'rd'
          : number + 'th';

      return formattedNumber;
    } else return 0;
  };

  const getPaymentFrequency = (paymentFrequencyId) => {
    if (!paymentFrequencyId > 0 || !props.paymentScheduleTypes?.length > 0)
      return 'unknown';
    else {
      let _paymentFrequency = props.paymentScheduleTypes.filter(
        (payment) => payment.id === paymentFrequencyId
      );
      return _paymentFrequency[0]?.name;
    }
  };

  //filter and sort bank accounts for display
  useEffect(() => {
    if (!props?.bankAccount || props?.bankAccount?.length < 1) {
      return;
    } else {
      let _filteredAccounts = [];
      let _sortedAccounts = [];

      _filteredAccounts = props?.bankAccount?.filter((account) => {
        //return accounts that are deleted
        if (!account.isDeleted) return account;
      });

      //sort bank accounts and display default account at top
      _sortedAccounts = _filteredAccounts?.sort((a, b) => {
        if (a.isPrimary) {
          return -1;
        }
        if (b.isPrimary) {
          return 1;
        } else return 0;
      });
      setSortedAccounts(_sortedAccounts);
    }
  }, [props?.bankAccount?.length, props?.bankAccount, props?.member?.id]);

  return (
    <>
      {(sortedAccounts?.length > 0 &&
        sortedAccounts?.map((account, index) => {
          return (
            <Box
              key={account.id}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
              }}
            >
              <Box className="col-1" sx={{ paddingTop: '8px' }}>
                {account.prenoteStatusId === 4 ? (
                  <Box>
                    <ErrorOutlineIcon
                      sx={{ color: 'rgba(211, 47, 47)', margin: '6px auto' }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <AccountBalanceIcon
                      sx={{ color: 'primary.main', margin: '6px auto' }}
                    />
                  </Box>
                )}
              </Box>

              {/* Member Name and Account Type */}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // paddingLeft: '6px',
                  // width: '100%',
                }}
                className="col-10"
              >
                {/* Account Name - 4 digit view */}
                <Typography
                  variant="body1"
                  sx={
                    {
                      //   fontFamily: 'Poppins',
                      //   fontWeight: '500',
                      //   color: 'text.primary',
                      //   fontSize: '14px!important',
                    }
                  }
                >
                  {capitalizeFirstLetter(props?.member?.firstName)}{' '}
                  {capitalizeFirstLetter(props?.member?.lastName)}'s{' '}
                  {props.getMemberAccountType(account.bankAccountTypeId)}{' '}
                  Account
                </Typography>
                <Typography
                  variant="body1"
                  sx={
                    {
                      //   fontFamily: 'Poppins',
                      //   fontWeight: '500',
                      //   color: 'text.primary',
                      //   fontSize: '14px!important',
                    }
                  }
                >
                  {getPaymentFrequency(props?.payment[0]?.paymentFrequencyId)},{' '}
                  {dayFormatter(
                    props?.payment[0]?.scheduledPaymentDates[0]?.dayOfMonth
                  )}
                  {props?.payment[0]?.scheduledPaymentDates[1]
                    ? ` and ${dayFormatter(
                        props?.payment[0]?.scheduledPaymentDates[1]?.dayOfMonth
                      )}`
                    : ' of the month'}
                </Typography>
                {/* Bank Info 4 digit account*/}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        fontSize: '14px!important',
                        lineHeight: '20px',
                        fontWeight: 400,
                      }}
                    >
                      {account.bankName || 'Account'}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        fontSize: '14px!important',
                        lineHeight: '20px',
                        fontWeight: 400,
                      }}
                    >
                      {`Account ending in (...${account.accountNumber?.slice(
                        -4
                      )})`}
                    </Typography>
                  </>

                  {/* Full bank account number if user has permissions and edit wallet drawer is not open */}
                  {/* {props?.permissionsEnabler?.viewFullBankAccounts &&
                    !props.editWalletDrawerOpen &&
                    isShowFullAccount === index && (
                      <Typography
                        variant="body2"
                        sx={paymentsTabStyles.bankAccountText()}
                      >
                        {`${account.bankName || 'Account '} - ${
                          account?.accountNumber
                        }    Routing - ${account?.routingNumber}`}
                      </Typography>
                    )} */}
                </Box>
              </Box>
            </Box>
          );
        })) || (
        <Typography variant="h6" sx={{ padding: '10px' }}>
          No Bank Information
        </Typography>
      )}
    </>
  );
}
